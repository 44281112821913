import React from "react";
import { Row } from "react-bootstrap";
import { StaticImage } from "gatsby-plugin-image";
import CookbookSection from "../../components/cookbookSection";
import DutchOvenCookingTipsLink from "../../components/dutchOvenCookingTipsLink";

const DutchOvenBreakfastsCookbookSection = ({ location }) => {
  return (
    <CookbookSection
      location={location}
      sectionLink="dutch-oven-breakfasts"
      sectionName="Breakfasts for Dutch Oven"
      sectionTitle="Breakfasts for Dutch Oven"
      prevLink="/cookbook/breakfasts/"
      nextLink="/cookbook/dutch-oven-breads/"
    >
      <Row className="d-flex pb-4 pt-4 flex-wrap justify-content-center" id="cookbook-top-text">
        <div className="ps-4 pe-4 pb-4 cookbook-top-section d-flex flex-column">
          <div>Try cooking with a Dutch oven and you'll never go without it</div>
          <div className="pt-2">
            <StaticImage width={300} src="../../images/cookbook/dutch-oven-over-fire.jpg" alt="Dutch Oven over fire" />
          </div>
        </div>
        <div className="ps-4 pe-4 pb-4 cookbook-top-section d-flex flex-column">
          <div className="flex-fill">... unless your hiking through the mountains</div>
          <div className="pt-2">
            <StaticImage width={300} src="../../images/cookbook/tent-with-hammock.jpg" alt="Tent with Hammock" />
          </div>
        </div>
      </Row>
      <DutchOvenCookingTipsLink />
    </CookbookSection>
  );
};

export default DutchOvenBreakfastsCookbookSection;
